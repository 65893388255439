import React, { Component } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Form, 
    CardHeader,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
  } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Login from 'views/IndexSections/Login';



export default class Service extends Component {

    render(){
    return (
        <>
          <DemoNavbar />
          <main ref="main">
              <Login />
          </main>
          <SimpleFooter />
        </>
      );
    }
}
