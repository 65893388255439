/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 shape-default">            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="row-grid justify-content-between align-items-center">
              <Col lg="7">
                <h3 className="display-3 text-white">
                 Les services
                  <span className="text-white">que nous vous offrons</span>
                </h3>
                <p className="lead text-white">
               -  Approvisionnement selon les modes offerts par Énergir ou Gazifère.
                </p>
                <p className="lead text-white"> 
                - Feuille de prix journalière pour information de marché.
                </p>
                <p className="lead text-white">
               - Fourniture de la molécule de gaz naturel à prix fixe, à prix variable ou de
façon hybride (donc partiellement fixe et partiellement variable).
                </p>
              



                
                <div className="btn-wrapper">
                  <Button  href="mailto:nvincent@nugaz.com" color="success">
                    Contactez-nous
                  </Button>
                  <Button
                    className="btn-white"
                    color="default"
                    to="/contact-page"
                    tag={Link}
                  >
                    Voir notre équipe
                  </Button>
                </div>
              </Col>
              <Col className="mb-lg-auto" lg="5">
                <div className="transform-perspective-right">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-2">
                      <div className="text-muted text-center mb-3">
                        <h3> D'autres services </h3>
                      </div>
                      <div className="btn-wrapper text-center">
                      <p>
                      -  Information de marché offerte à la clientèle et guidance pour choix d’approvisionnement selon objectifs du client.
                </p>
                <p>
               - Équipe locale pour communication rapide et efficace.
Facturation, contrat et toute autre communication en français ou anglais
selon les choix du client.
                </p>

                <p>
               - Mandataire auprès du distributeur pour le client pour faciliter sa gestion.
                </p>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-2">
                        <small> Cela vous intéresse ? </small>
                      </div>
                      <Form role="form">
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            href="mailto:nvincent@nugaz.com?subject=Vos services m'intéresse"
                          >
                            J'embarque !
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>

        <section className="section pb-0 bg-gradient-white" >
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3" style={{margintop:100}}>
                   
                    <div className="pl-4">
                      
                    </div>
                  </div>
                  
                  
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
          
          </section>

          <section className="section pb-0 bg-gradient-white" >
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  
                </Col>
                <Col className="order-lg-1" lg="6">
                 
                  
                  
                </Col>
              </Row>
            </Container>
          
            
          </section>
      </>
    );
  }
}

export default Login;
