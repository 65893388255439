/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: require("assets/img/ill/naturalGazPic.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/ill/naturalGazPhoto.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/ill/naturalGazTanks.jpg"),
    altText: "",
    caption: "",
    header: ""
  }
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right mb-5">
                  <UncontrolledCarousel items={items} />
                </div>
                
              </Col>
              
              <Col className="mb-5 mb-lg-0" lg="5">
              <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-trophy text-warning" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Nos forces</h4>
                      <p className="text-white">
                        <h6 className="text-white" style={{textDecorationLine:"underline"}}>Compétence</h6>
                       Notre connaissance accrue du marché de l’approvisionnement et de la distribution au
                       Québec.
                      </p>

                      <p className="text-white">
                      <h6 className="text-white" style={{textDecorationLine:"underline"}}>Proximité</h6>
                      Un service personnalisé tenant compte de votre niveau de gestion du risque.
                      </p>

                      <p className="text-white">
                      <h6 className="text-white" style={{textDecorationLine:"underline"}}>Transparence</h6>
                       Un prix compétitif et un accompagnement d’un professionnel de l’industrie pour une
                      prise de décision réfléchie.
                      </p>

                      <p className="text-white">
                      <h6 className="text-white" style={{textDecorationLine:"underline"}}>Fiabilité et intégrité</h6>
                      Approvisionnement fiable (Shell Energy North America Canada Inc.) et
                      atténuation des risques.
                      </p>
                    </div>
                  </div>                
                
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default Carousel;
