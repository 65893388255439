import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardImg,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class GazNaturel extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <h1
                      className="display-3 text-white"
                      style={{ fontSize: 50 }}
                    >
                      Le gaz naturel
                    </h1>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section" style={{ marginTop: -180 }}>
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-warning shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/ill/gazNaturel.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-warning"
                          points="0,52 583,95 0,95"
                        />
                       
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        Qu'est-ce que c'est?
                      </h4>
                      <p className="lead text-italic text-white">
                        Le gaz naturel est un mélange gazeux constitué à 95 % de
                        méthane. Naturellement présent dans les réservoirs
                        souterrains (puits) et maintenant exploités aussi dans
                        les schistes, le gaz naturel est le combustible fossile
                        en plus grande croissance en Amérique du Nord.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-2">
                      <i className="ni ni-world-2" />
                    </div>
                    <h3>Apprenez-en sur le gaz naturel</h3>
                    <p className="lead">
                      Le gaz naturel est de plus en plus utilisé étant donné son
                      abondance ainsi que ses caractéristiques moins polluantes
                      que le charbon et le pétrole. Le gaz naturel émet 40%
                      moins de CO2 que le pétrole.
                    </p>
                    <p>
                      Le gaz naturel consommé au Québec est acheminé par
                      conduite souterraine. L’approvisionnement du gaz provient
                      principalement des bassins sédimentaires de l’Ouest
                      canadien et des schistes en proximité des Grands Lacs.
                    </p>
                    <p>
                      Le gaz naturel étant présent en abondance depuis quelques
                      années, son prix est fort concurrentiel par rapport à
                      l’électricité et le mazout.
                    </p>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      href="mailto:nvincent@nugaz.com"
                    >
                      Contactez-nous pour plus d'information
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default GazNaturel;
