import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class ContactPage extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">Notre équipe</h1>
                      <p className="lead text-white">
                        Notre équipe est composée de 3 professionels qui seront
                        vous servir et répondre à vos besoins.
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="mailto:nvincent@nugaz.com"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-email-83" />
                          </span>
                          <span className="btn-inner--text">Écrivez-nous</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="tel:+4189530318"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-mobile-button" />
                          </span>
                          <span className="btn-inner--text">Appelez-nous</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="mb-4">
                              <img
                                
                                alt="..."
                                style={{ width : 140, height:140}}
                                className="img-fluid rounded-circle shadow-lg"

                                src={require("assets/img/NicolasVincent.jpg")}
                              />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Nicolas Vincent
                          </h6>
                          <p className="description mt-3">
                            Directeur général de Solution Nügaz
                          </p>
                          <div>
                            <Badge
                              href="mailto:nvincent@nugaz.com"
                              color="warning"
                              pill
                              className="mr-1"
                            >
                              nvincent@nugaz.com
                            </Badge>
                            <Badge
                              href="tel:+4189530318"
                              color="warning"
                              pill
                              className="mr-1"
                            >
                              418-953-0318
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                        <div className="mb-4">
                              <img
                                alt="..."
                                style={{ height : 140, width: 140}}
                                className="img-fluid rounded-circle shadow-lg"

                                src={require("assets/img/PaulRaymond.jpg")}
                              />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Paul Raymond
                          </h6>
                          <p className="description mt-3">
                            Directeur des ventes - Ouest du Québec
                          </p>
                          <div>
                            <Badge
                              href="mailto:praymond@nugaz.com"
                              color="warning"
                              pill
                              className="mr-1"
                            >
                              praymond@nugaz.com
                            </Badge>
                            <Badge
                              href="tel:+4388219237"
                              color="warning"
                              pill
                              className="mr-1"
                            >
                              438-821-9237
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                        <div className="mb-4">
                              <img
                                alt="..."
                                style={{ height : 140, width: 140}}
                                className="img-fluid rounded-circle shadow-lg"

                                src={require("assets/img/MarioCauchon.jpg")}
                              />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Mario Cauchon
                          </h6>
                          <p className="description mt-3">
                            Directeur des ventes – Est du Québec
                          </p>
                          <div>
                            <Badge
                              href="mailto:mcauchon@nugaz.com"
                              color="warning"
                              pill
                              className="mr-1"
                            >
                              mcauchon@nugaz.com
                            </Badge>
                            <Badge
                              href="tel:+5819997873"
                              color="warning"
                              pill
                              className="mr-1"
                            >
                              581-999-7873
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section pb-0 pt-0">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-black">
                        {" "}
                        Nos informations
                      </h4>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                            <i className="ni ni-shop" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-info">Notre emplacement</h5>
                          <p>
                            91-1 Boulevard Bastien, Wendake, Québec, G0A 4V0
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-mobile-button" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">Nos téléphones</h5>
                          <p>
                            Tél:
                            <a href="tel:+4184070863"> 418-407-0863 </a>
                          </p>
                          <p>
                            Fax:
                            <a href="tel:+4184070890"> 418-407-0890 </a>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5 ">
                    <iframe
                      style={{ borderRadius: 5 }}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2728.3536467777553!2d-71.35388318439536!3d46.856412479142115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb898794c145b41%3A0x93689dbc6c4d5c4b!2s91%20Boulevard%20Bastien%2C%20Wendake%2C%20QC%20G0A%204V0!5e0!3m2!1sfr!2sca!4v1595432464928!5m2!1sfr!2sca"
                      width="80%"
                      height="450"
                      frameborder="0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              ></svg>
            </div>
          </section>
          <section className="section section-lg mt-4">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Au plaisir de vous rencontrer
                      </h3>
                      <p className="lead text-white mt-3">
                        N'hésitez-pas à nous contacter si vous avez des
                        questionnements à propos de Solution Nügaz
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ContactPage;
